"use client";
import { useEffect, useState } from "react";
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";
import navigationMockData from "@/mocks/navigation.json";
import footerLinks from "@/mocks/footerEng.json";
import Script from "next/script";
import { filterMainNav } from "@/utils/filterMainNav";
import { Nav } from "@/types/Nav";
import getUserLocation from "@/lib/location/getUserLocationFromClient";

const Error = () => {
  const [filteredNavigation, setFilteredNavigation] = useState<Nav>(
    navigationMockData as Nav,
  );
  useEffect(() => {
    const loc = getUserLocation();
    const filteredNavigation = filterMainNav(loc);
    setFilteredNavigation(filteredNavigation);
  }, []);
  return (
    <div>
      <Script src="/scripts/newrelic" />
      <Navigation navigation={filteredNavigation} />
      <div className="flex items-center justify-center">
        <div className="m-auto text-center">
          <h1 className="text-lg">
            There has been a 500 error on the edX servers
          </h1>
          <p className="text-base">
            Please wait a few seconds and then reload the page
          </p>
        </div>
      </div>
      <Footer links={footerLinks} />
    </div>
  );
};

export default Error;
