import Header from "@/components/Footer/Header";
import MainLinks from "@/components/Footer/MainLinks";
import SecondaryLinks from "@/components/Footer/SecondaryLinks";
import FooterFooter from "@/components/Footer/Footer";
import { FooterLinks } from "@/types/Footer";

type Props = {
  links: FooterLinks;
};

export default function Footer({ links }: Props) {
  return (
    <>
      <div className="bg-primary text-primary-foreground pt-16">
        <footer className="flex justify-between max-w-screen-xl	mx-auto px-4 pb-4 ">
          <div className="flex flex-col w-full">
            <Header />
            <MainLinks links={links.main} />
            <SecondaryLinks links={links.secondary} />
            <FooterFooter />
          </div>
        </footer>
      </div>
    </>
  );
}
