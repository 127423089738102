import navigationMockData from "../mocks/navigation.json";
import { Nav } from "@/types/Nav";

export const filterMainNav = (loc: string | null = ""): Nav => {
  const showBootcamp = loc === "US";

  const filteredNavigation = navigationMockData.sections.map((section) => {
    const sectionsFiltered = section.items.filter((item: any) => !item.usOnly);
    const productListsFiltered = sectionsFiltered.map((item: any) => {
      if (item.productLists) {
        const filteredProductLists = item.productLists.filter(
          (productList: any) => !productList.usOnly,
        );
        return { ...item, productLists: filteredProductLists };
      }
      return item;
    });
    return { ...section, items: productListsFiltered };
  });
  return {
    ...navigationMockData,
    sections: showBootcamp ? navigationMockData.sections : filteredNavigation,
  };
};
